import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import InputField from "@/components/Common/Inputs/Input/Input";
import Button from "@/components/Common/Buttons/Button/Button";
import PlusMinusInput from "@/components/Common/Inputs/PlusMinusInput/PlusMinusInput";
import HeartIcon from "@/components/Common/Icons/HeartIcon/";
import xIcon from "@/components/Common/Icons/xIcon/";
import AddToFavourites from "@/components/Components/AddToFavourites/AddToFavourites";


export default {
    name: 'CartItem',
    props: {
        product: {
            type: Object,
        },
    },
    components: {
        Dropdown,
        InputField,
        Button,
        PlusMinusInput,
        HeartIcon,
        xIcon,
        AddToFavourites
    },
    watch: {
        product: {
            async handler(newVal, oldVal){
                let quantity = newVal.quantity;
                if (quantity < 1) {
                    quantity = 1;
                }
                if (quantity > 100) {
                    quantity = 100;
                }
                this.$emit('update:modelValue', newVal.quantity);
                await this.$store.dispatch('cart/changeProductFromCart', {
                    productId: this.$props.product.id,
                    quantity: quantity,
                });
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        async removeItemFromCart(id) {
            await this.$store.dispatch('cart/removeProductFromCart', id);
        },
    }
}